import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'test';
  map: L.Map;
  radius: any = '100';

  latlon = L.latLng('47.901505575287', '13.956660265513674');
  circle: any;
  marker: any;

  constructor() {}

  ngOnInit(): void {
    this.map = L.map('map', {
      center: this.latlon,
      zoom: 9,
    });

    this.marker = L.marker(this.latlon).addTo(this.map);

     L.tileLayer(
      'http://{s}.google.com/vt?lyrs=p&x={x}&y={y}&z={z}',
      {
        maxZoom: 25,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    ).addTo(this.map);


    this.map.on('click', (e) => {
      console.log(e.latlng);
      this.latlon = e.latlng;
      if (this.marker && this.map.hasLayer(this.marker)) {
         this.map.removeLayer(this.marker); // remove
         this.map.removeLayer(this.circle);
      }
      this.marker = L.marker([e.latlng.lat, e.latlng.lng], this.marker).addTo(this.map);
      this.setMarker(this.radius);
    });

    this.setMarker(this.radius);
  }


  // tslint:disable-next-line: typedef
  setMarker(radius: any) {
    // this.circle.clearLayers();

    const radi = radius * 1000;
    this.circle = L.circle(this.latlon, {
      radius: radi,
    });
    this.circle.addTo(this.map);
  }

  // tslint:disable-next-line: typedef
  showMap() {}

  printPage() {
    window.print();
  }
}
