<div class="map-header">
  <form class="example-form">
    <mat-form-field class="example-form-field">
      <mat-label>Radius in km</mat-label>
      <input matInput type="number" [(ngModel)]="radius" name="radius">
      <button  matSuffix mat-icon-button aria-label="Clear" (click)="radius=''">
        <mat-icon aria-hidden="false" fontIcon="close"></mat-icon>
      </button>
    </mat-form-field>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <span class="mkbutton">
    <button mat-stroked-button (click)="setMarker(radius)">Anzeigen</button>&nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-stroked-button (click)="printPage()">Drucken</button>
 </span>
  </form> 
</div>
<div class="map-container">
  <div class="map-frame">
    <div id="map"></div>
  </div>
</div>